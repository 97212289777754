<template>
  <svg
    :style="iconStyle"
    :width="width"
    :height="height"
    viewBox="0 0 40 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 22C12.8506 22 12.7012 22.0219 12.5587 22.0681C11.7487 22.3312 10.8969 22.5 9.99998 22.5C9.1031 22.5 8.25123 22.3312 7.4406 22.0681C7.2981 22.0219 7.14935 22 6.99998 22C3.12123 22 -0.0206461 25.155 -2.11029e-05 29.0387C0.0087289 30.68 1.3581 32 2.99998 32H17C18.6419 32 19.9912 30.68 20 29.0387C20.0206 25.155 16.8787 22 13 22ZM9.99998 20C13.3137 20 16 17.3137 16 14C16 10.6862 13.3137 8 9.99998 8C6.68623 8 3.99998 10.6862 3.99998 14C3.99998 17.3137 6.68623 20 9.99998 20ZM37 0H13C11.3456 0 9.99998 1.39062 9.99998 3.09938V6C11.4637 6 12.8187 6.42375 14 7.1125V4H36V22H32V18H24V22H19.235C20.4287 23.0431 21.305 24.4206 21.7156 26H37C38.6544 26 40 24.6094 40 22.9006V3.09938C40 1.39062 38.6544 0 37 0Z"
      fill="#FEC952"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '32',
    },
    width: {
      type: String,
      default: '40',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
